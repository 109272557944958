<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="25%"
      top="6%"
      :close-on-click-modal="false"
      class="add2Eidt-Survey-dialog portrait"
      :title="title"
      @close="closed"
    >
      <div class="fontB6">
        <el-form
          :model="surveyDataForm"
          :rules="rulesList"
          ref="SurveyDataForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="projectId">
            <template slot="label">
              <div class="labelTitle">
                {{ $t('dc:产品名称') }}
                <em>*</em>
              </div>
            </template>
            <div>
              <el-select
                v-model="surveyDataForm.projectId"
                filterable
                :disabled="disabled"
                :placeholder="$t('dc:请选择')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="surveyName">
            <template slot="label">
              <div class="labelTitle">
                {{ $t('dc:调查名称') }}
                <em>*</em>
              </div>
            </template>
            <div class="categoryContent">
              <el-input
                v-model="surveyDataForm.surveyName"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="launchTime">
            <template slot="label">
              <div class="labelTitle">
                {{ $t('dc:发布时间') }}
                <em>*</em>
              </div>
            </template>
            <div>
              <el-date-picker
                style="width: 100%"
                v-model="surveyDataForm.launchTime"
                type="daterange"
                range-separator="-"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                :start-placeholder="$t('dc:开始日期')"
                :end-placeholder="$t('dc:结束日期')"
              >
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item prop="url">
            <template slot="label">
              <div class="labelTitle">
                {{ $t('dc:URL') }}
                <em>*</em>
              </div>
            </template>
            <div class="categoryContent">
              <el-input v-model="surveyDataForm.url" :placeholder="$t('dc:请输入')"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="description">
            <template slot="label">
              <div class="labelTitle">
                {{ $t('dc:描述') }}
                <em>*</em>
              </div>
            </template>
            <div class="categoryContent">
              <el-input
                type="textarea"
                :rows="2"
                v-model="surveyDataForm.description"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="msg">
              {{ contextOne }}
            </div>
            <div class="msg">
              <a href="#" @click="goSurveyUrl">https://survey.publicisgrowthos.com/</a>
            </div>
            <div class="msg">
              {{ contextTwo }}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button class="marginR20" type @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="primary" @click="submitForm('SurveyDataForm')">{{
          $t('dc:确认')
        }}</el-button>
      </div>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="dielogTitle"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>
<script>
import config from '@/api/config'
import { $http } from '../../api/http'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'Add2EditSurveyDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    rowData: {},
  },
  data() {
    var projectIdRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var urlRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      }
      if (
        !new RegExp(
          '^https?:\\/\\/(([a-zA-Z0-9_-])+(\\.)?)*(:\\d+)?(\\/((\\.)?(\\?)?=?&?[a-zA-Z0-9_-](\\?)?)*)*$',
        ).test(value)
      ) {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    return {
      show: this.visible,
      show4Dialog: false,
      dielogTitle: this.$t('dc:提醒'),
      content: this.$t('dc:确认添加'),
      disabled: false,
      otherClass: 'home',
      contextOne: this.$t('dc:单击下面的链接以创建新调查'),
      contextTwo: this.$t('dc:或者您可以去其他平台创建调查'),
      productList: [],
      value: '',
      DateValue: '',
      input: '',
      surveyDataForm: {
        projectId: '',
        surveyName: '',
        launchTime: '',
        url: '',
        description: '',
      },
      rulesList: {
        projectId: [{ validator: projectIdRule, trigger: 'blur' }],
        launchTime: [{ validator: projectIdRule, trigger: 'blur' }],
        surveyName: [{ validator: projectIdRule, trigger: 'blur' }],
        url: [{ validator: urlRule, trigger: 'blur' }],
        description: [{ validator: projectIdRule, trigger: 'blur' }],
      },
    }
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {
    pickerOptions() {
      return {
        firstDayOfWeek: 1,
        disabledDate: (time) => {
          return time.getTime() <= Date.now() - 86400000
        },
      }
    },
  },
  mounted() {
    this.initEvent()
  },

  methods: {
    initEvent() {
      this.getProductList()
      if (this.rowData.surveyId) {
        this.disabled = true
        this.getSurveyDataDetail({ surveyId: this.rowData.surveyId })
      }
    },
    // 获取产品列表
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    // 获取问卷详情
    async getSurveyDataDetail(params) {
      let { status, data } = await $Api.survey.detailsSurvey(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.surveyDataForm = data
      }
    },
    goSurveyUrl() {
      window.open('https://survey.publicisgrowthos.com/')
    },
    closed() {
      this.$emit('close')
      this.show = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.show4Dialog = true
          if (this.rowData.surveyId) this.content = this.$t('dc:确认修改')
          else this.content = this.$t('dc:确认添加')
        } else {
          return false
        }
      })
    },
    confrom() {
      let param = {
        surveyId: this.rowData.surveyId || '',
        projectId: this.surveyDataForm.projectId,
        surveyName: this.surveyDataForm.surveyName,
        stDate: this.surveyDataForm.launchTime[0],
        edDate: this.surveyDataForm.launchTime[1],
        url: this.surveyDataForm.url,
        description: this.surveyDataForm.description,
      }
      if (this.rowData.surveyId) {
        this.editSurveyData(param)
      } else {
        this.addSurveyData(param)
      }
      this.show4Dialog = false
    },
    async addSurveyData(params) {
      let { status } = await $Api.survey.addSurvey(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
        this.$emit('queryApi')
      }
    },
    async editSurveyData(params) {
      let { status } = await $Api.survey.editSurvey(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
        this.$emit('queryApi')
      }
    },
  },
}
</script>
<style lang="scss">
.labelTitle {
  color: #000;
  font-weight: 500;
}
em {
  color: red;
}
.msg {
  height: 18px;
  font-size: 12px;
  a {
    text-decoration: underline;
    color: rgba(74, 227, 13, 1);
  }
}
</style>
