<template>
  <div class="Survy common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:反馈中心') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:调查列表') }}</span>
      </template>
      <template slot="headerGroup">
        <el-select
          v-model="pageConfig.projectId"
          filterable
          :clearable="true"
          :placeholder="$t('dc:请选择产品')"
          @change="queryApi"
          class=""
          style="margin-right: 10px; width: 240px"
        >
          <el-option
            v-for="item in projectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button class="btn-black" type="info" @click="onAdd">{{
          $t('dc:新增调查信息')
        }}</el-button>
        <!-- <el-button type="primary" icon="el-icon-refresh">{{$t('dc:刷新')}}</el-button> -->
      </template>
      <template slot="queryList"> </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="120" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip effect="dark" :content="$t('dc:编辑')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                  @click="showEdit(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip effect="dark" :content="$t('dc:删除')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-delete padding12 nicon"
                  @click="showDelectSurvey(scope.row)"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <add2EditSurveyDialog
      v-if="showSurveyDialog"
      :visible.sync="showSurveyDialog"
      :title="title"
      :rowData="rowData"
      @queryApi="queryApi"
    ></add2EditSurveyDialog>
    <Forbid4Delete
      v-if="showSurveyDelDialog"
      :visible.sync="showSurveyDelDialog"
      :title="delTitle"
      :content="delContent"
      @confrom="confrom"
    ></Forbid4Delete>
  </div>
</template>
<script>
import Forbid4Delete from '@/views/dialog/usuallyDialog'
import add2EditSurveyDialog from '@/views/dialog/add2EditSurveyDialog'
export default {
  name: 'surveylist',
  mixins: [$PCommon.TableMixin],
  components: {
    add2EditSurveyDialog,
    Forbid4Delete,
  },
  data() {
    return {
      title: this.$t('dc:新增调查信息'),
      delTitle: this.$t('dc:提醒'),
      delContent: this.$t('dc:确定删除?'),
      pageConfig: {
        projectId: '',
      },
      projectList: [],
      loading: false,
      showSurveyDialog: false,
      showSurveyDelDialog: false,
      Surveyobj: {},
      tableData: [
        {
          announcementId: 1,
        },
      ],
      rowData: {},
    }
  },
  mounted() {
    this.initEvent()
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'surveyId',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 60, // 默认宽度，像素
        },
        {
          key: 'projectName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 80,
        },
        {
          key: 'surveyName',
          name: this.$t('dc:调查名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'launchTime',
          name: this.$t('dc:创建时间'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
        {
          key: 'url',
          name: this.$t('dc:URL'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
        },
        {
          key: 'author',
          name: this.$t('dc:发布者'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  methods: {
    initEvent() {
      this.getProductList()
      this.queryApi()
    },
    // 获取产品列表
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.projectList = data
      }
    },
    // 获取问卷列表
    async queryApi() {
      this.loading = true
      let { status, data } = await $Api.survey.getSurveyList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data.list
        this.pageConfig.total = data.total
      }
      this.loading = false
    },
    updDataList() {
      this.queryApi()
    },
    onAdd() {
      this.title = this.$t('dc:新增调查信息')
      this.rowData = {}
      this.showSurveyDialog = true
    },
    showEdit(row) {
      this.title = this.$t('dc:编辑调查信息')
      this.rowData = row
      this.showSurveyDialog = true
    },
    showDelectSurvey(row) {
      this.rowData = row
      this.showSurveyDelDialog = true
    },
    confrom() {
      this.delectSurvey({ surveyId: this.rowData.surveyId })
    },
    async delectSurvey(params) {
      let { status } = await $Api.survey.delSurvey(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.showSurveyDelDialog = false
        this.queryApi()
      }
    },
  },
}
</script>
